// Override default variables before the import

$primary: #495fef;
$secondary: #f5f5f7;
$success: #56b381;
$bg: #ffffff;
$heading: #161c2d;
$text: #161c2d;
$white: $bg;
$black: $heading;
$body-color: $text;
$headings-color: $text;
$body-bg: $bg;
$font-family-base: "Inter", sans-serif;
$headings-font-family: $font-family-base;
$headings-font-weight: 700;

//Import font
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.has-offcanvas {
  width: 100%;
  height: 100%;
  // overflow: hidden !important;
}

// Fix for flush list group
.list-group-flush:first-child,
.list-group-item:first-child {
  border-top: 0;
}

// Fix for Gallery Lightbox (react-image package)
.react-images__blanket,
.react-images__positioner {
  z-index: 999999 !important;
}

// Fix for Modal
.modal,
.modal-backdrop {
  z-index: 99999 !important;
}
